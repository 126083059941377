export enum EnumUserRole {
    Administrator = 'ADMIN',
    Student = 'STUDENT',
    Support = 'SUPPORT'
}

export enum EnumUserGender {
    Male = 'MALE',
    Female = 'FEMALE',
    Uninformed = 'UNINFORMED',
    Other = 'OTHER'
}

export enum EnumUserSubscription {
    Active = 'ACTIVE',
    Canceled = 'CANCELED',
    Expired = 'EXPIRED'
}