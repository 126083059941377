import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  //Variáveis
  listToasts: Array<{ title: string, body: string, type: string }> = [];

  //Sucesso
  success(title: string, body: string, timeout?: number) {
    const toast = { title, body, type: 'success' };
    this.listToasts.push(toast);

    setTimeout(() => { this.remove(toast); }, timeout || 5000);
  }

  //Informação
  info(title: string, body: string, timeout?: number) {
    const toast = { title, body, type: 'info' };
    this.listToasts.push(toast);

    setTimeout(() => { this.remove(toast); }, timeout || 5000);
  }

  //Aviso
  warning(title: string, body: string, timeout?: number) {
    const toast = { title, body, type: 'warning' };
    this.listToasts.push(toast);

    setTimeout(() => { this.remove(toast); }, timeout || 5000);
  }

  //Erro
  error(title: string, body: string, timeout?: number) {
    const toast = { title, body, type: 'danger' };
    this.listToasts.push(toast);

    setTimeout(() => { this.remove(toast); }, timeout || 5000);
  }

  //Remover Toast
  remove(toast: any) {
    this.listToasts = this.listToasts.filter(t => t != toast);
  }

}
