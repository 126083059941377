import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestApiService } from './rest-api.service';
import { AccessToken, Login, User } from 'src/models';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { EnumUserRole } from 'src/models/enums';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends RestApiService {
  //Variáveis
  private _urlLogin = '/users/login';
  private _urlLogout = '/users/logout';

  public accessToken = new AccessToken({}); //Token de Acesso
  public userLogged: User = new User({}); //Usuário Logado

  constructor(
    public http: HttpClient,
    private _router: Router,
    private _jwtService: JwtHelperService
  ) {
    super(http);
  }

  login(user: User) {
    const request: EventEmitter<User> = new EventEmitter();

    this.post(this._urlLogin, user, Login).subscribe(
      next => {
        //Token
        localStorage.setItem('token', next.token);
        const decodeToken = this._jwtService.decodeToken(next.token);
        this.accessToken = new AccessToken(decodeToken);
        //Usuário
        request.emit(next.user);
        this.userLogged = new User(decodeToken);

        if (next.user.role == EnumUserRole.Administrator)
          this._router.navigate(['a']);
        else if (next.user.role == EnumUserRole.Student)
          setTimeout(() => { this._router.navigate(['p']); }, 1000);
      },
      error => {
        request.error(error);
      }
    );

    return request.asObservable();
  }

  logout() {
    const request: EventEmitter<boolean> = new EventEmitter();

    this.post(this._urlLogout, this.accessToken, AccessToken).subscribe(
      next => {
        localStorage.clear();
        request.emit(true);
      },
      error => {
        request.error(error);
      }
    );

    return request.asObservable();
  }

  getUser(): User {
    const token = localStorage.getItem('token') || '';
    return new User(this._jwtService.decodeToken(token));
  }

  setUser() {
    const token = localStorage.getItem('token') || '';
    const decodeToken = this._jwtService.decodeToken(token);
    this.accessToken = new AccessToken(decodeToken);
    this.userLogged = new User(decodeToken);
  }

}
