export enum EnumBrazilState {
    Acre = 'AC',
    Alagoas = 'AL',
    Amapa = 'AP',
    Amazonas = 'AM',
    Bahia = 'BA',
    Ceara = 'CE',
    DistritoFederal = 'DF',
    EspiritoSanto = 'ES',
    Goias = 'GO',
    Maranhao = 'MA',
    MatoGrosso = 'MT',
    MatoGrossodoSul = 'MS',
    MinasGerais = 'MG',
    Para = 'PA',
    Paraiba = 'PB',
    Parana = 'PR',
    Pernambuco = 'PE',
    Piaui = 'PI',
    RiodeJaneiro = 'RJ',
    RioGrandedoNorte = 'RN',
    RioGrandedoSul = 'RS',
    Rondonia = 'RO',
    Roraima = 'RR',
    SantaCatarina = 'SC',
    SaoPaulo = 'SP',
    Sergipe = 'SE',
    Tocantins = 'TO',
    Federal = 'FEDERAL'
}
