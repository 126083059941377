import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private _authService: AuthService) { }

  ngOnInit(): void {
    let viewMode = localStorage.getItem('viewMode') || 'light';

    if (viewMode == 'dark') document.body.classList.add('dark-mode');
    else document.body.classList.remove('dark-mode');

    this._authService.setUser();
  }

}
