import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { IRest } from 'src/models/rest.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  //Variáveis
  private endpoint = environment.endpoint();
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
    })
  };

  constructor(
    protected http: HttpClient
  ) { }

  protected get<T extends IRest>(url: string, type: { new(): T; }): Observable<T | Array<T>> {
    if (!url.includes('http')) url = (this.endpoint + url);
    return this.http.get(url, this.httpOptions).pipe(
      retry(1),
      map((data) => this.deserialize(data, type))
    );
  }

  // get sem IRest
  protected getData<T = any>(url: string): Observable<T> {
    if (!url.includes('http')) url = (this.endpoint + url);
    return this.http.get(url, this.httpOptions).pipe(
      retry(1),
      map((data) => <T>data)
    );
  }

  protected getBlob(url: string): Observable<Blob> {
    if (!url.includes('http')) url = (this.endpoint + url);
    return this.http.get(url, { responseType: 'blob' }).pipe(
      retry(1)
    );
  }

  protected post<T extends IRest>(url: string, data: IRest | IRest[], type: { new(): T; }): Observable<T> {
    let body: any;
    if (data instanceof Array)
      body = data.map(i => i.Serialize());
    else
      body = data.Serialize();

    return this.http.post((this.endpoint + url), JSON.stringify(body), this.httpOptions).pipe(
      map((res) => <T>this.deserialize(res, type))
    );
  }

  // post sem IRest
  protected postData<R = any>(url: string, data?: any): Observable<R> {
    return this.http.post((this.endpoint + url), data, this.httpOptions).pipe(
      map((res) => <R>res)
    );
  }

  protected sendFile<R = any>(url: string, file: File): Observable<R> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post((this.endpoint + url), formData, {
      reportProgress: true,
    }).pipe(
      map((res) => <R>res)
    );
  }

  protected put<T extends IRest>(url: string, data: IRest, type: { new(): T; }): Observable<T> {
    const body = data.Serialize();
    return this.http.put((this.endpoint + url), JSON.stringify(body), this.httpOptions).pipe(
      map((res) => <T>this.deserialize(res, type))
    );
  }

  protected del<T extends IRest>(url: string, type: { new(): T; }): Observable<T> {
    return this.http.delete((this.endpoint + url), this.httpOptions).pipe(
      map((res) => <T>this.deserialize(res, type))
    );
  }

  private deserialize<T extends IRest>(data: any, type: { new(): T; }): T | Array<T> {
    if (data instanceof Array) {
      const arrT: Array<T> = [];
      data.forEach(item => {
        const t: IRest = new type();
        arrT.push(t.Deserialize(item));
      });
      return arrT;
    } else {
      const t: IRest = new type();
      return t.Deserialize(data);
    }
  }

}
