import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  //Variáveis
  private endpoint = environment.endpoint();

  private cache: Map<string, HttpResponse<any>> = new Map(); //Mapa Para Persistir Cache de Requisições HTTP
  private endpointsCache = [ //Endpoins Para Realizar Cache
    '/subjects',
    '/examining-boards'
  ];

  constructor(
    private _router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //Rota Requisitada
    const url = request.url.replace(this.endpoint, '');

    //Verificar se a Rota é Para Realizar Cache
    if (this.endpointsCache.includes(url)) {
      //Obter e Retornar Requisição Cacheada
      const cachedResponse = this.cache.get(url);
      if (cachedResponse) return of(cachedResponse);
      //Se Não Houver Cache, Retornar Requisição e Persistir Resposta
      return next.handle(request).pipe(tap(
        (response) => {
          if (response instanceof HttpResponse)
            this.cache.set(url, response);
        },
        error => {
          if (error.status == 401) {
            localStorage.clear();
            this._router.navigate(['u']);
          }
        }
      ))
    }
    //Se Não For Para Realizar Cache, Retornar Requisição
    // return next.handle(request);
    return next.handle(request).pipe(tap(
      event => {
        //Qualquer Requisição HTTP
      },
      error => {
        if (error.status == 401) {
          localStorage.clear();
          this._router.navigate(['u']);
        }
      }
    ));
  }

}
