import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgxMaskModule } from 'ngx-mask';
import { NgHcaptchaModule } from 'ng-hcaptcha';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

import { ExamComponent } from './exam/exam.component';
import { ProfileComponent } from './profile/profile.component';
import { StudyComponent } from './study/study.component';
import { VideoTutorialComponent } from './video-tutorial/video-tutorial.component';


@NgModule({
  declarations: [
    ExamComponent,
    ProfileComponent,
    StudyComponent,
    VideoTutorialComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    NgbTooltipModule,
    NgbTypeaheadModule,
    NgxMaskModule.forRoot(),
    NgHcaptchaModule.forRoot({
      siteKey: '9058f1c4-fcaf-4075-b862-3bd647b51e0a',
      languageCode: 'pt'
    })
  ],
  exports: [
    ExamComponent,
    ProfileComponent,
    StudyComponent,
    VideoTutorialComponent
  ]
})
export class ModalModule { }
