import { IRest } from './rest.interface';
import { EnumBrazilState, EnumUserGender, EnumUserRole, EnumUserSubscription } from './enums';

export class UserSubscription implements IRest {
    className = 'Assinatura do Usuário';

    status!: EnumUserSubscription;
    expiresAt!: Date;

    constructor(data?: any) {
        if (data) this.Deserialize(data);
    }

    Serialize() {
        const out = {
            status: this.status,
            expiresAt: this.expiresAt.toISOString()
        };
        return out;
    }

    Deserialize(data: any) {
        if (data) {
            this.status = <EnumUserSubscription.Active>(data['status'] || EnumUserSubscription.Active);
            this.expiresAt = data['expiresAt'] ? new Date(data['expiresAt']) : new Date(2000, 0);
        }
        return this;
    }

}

export class User implements IRest {
    className = 'Usuário';

    id!: number;
    name!: string;
    lastName!: string;
    username!: string;
    email!: string;
    emailVerified!: boolean;
    subscription!: UserSubscription;
    password?: string;
    image!: string; //Uso Interno
    active!: boolean;
    role?: EnumUserRole;
    birthday!: string;
    city!: string;
    state!: EnumBrazilState;
    gender!: EnumUserGender;
    code?: string;
    createdAt!: Date;

    constructor(data?: any) {
        if (data) this.Deserialize(data);
    }

    Serialize() {
        const out = {
            id: this.id || undefined,
            name: this.name,
            lastName: this.lastName,
            username: this.username,
            email: this.email,
            emailVerified: this.emailVerified,
            subscription: this.subscription.Serialize(),
            password: this.password || undefined,
            active: this.active,
            role: this.role,
            birthday: new Date(`${this.birthday}T10:00:00.000Z`),
            city: this.city,
            state: this.state,
            gender: this.gender,
            code: this.code,
        };
        return out;
    }

    Deserialize(data: any) {
        if (data) {
            this.id = data['id'] || 0;
            this.name = data['name'] || '';
            this.lastName = data['lastName'] || '';
            this.username = data['username'] || '';
            this.email = data['email'] || '';
            this.emailVerified = data['emailVerified'] || false;
            this.subscription = new UserSubscription(data['subscription'] || {});
            this.password = data['password'] || '';
            this.active = data['active'] || false;
            this.role = <EnumUserRole>(data['role'] || EnumUserRole.Student);
            this.birthday = (data['birthday'] ? new Date(data['birthday']) : new Date(2000, 0)).toISOString().split('T')[0];
            this.city = data['city'] || '';
            this.state = <EnumBrazilState>(data['state'] || EnumBrazilState.EspiritoSanto);
            this.gender = <EnumUserGender>(data['gender'] || EnumUserGender.Uninformed);
            this.createdAt = data['createdAt'] ? new Date(data['createdAt']) : new Date(2000, 0);
        }
        return this;
    }

}

export class UserPreferencesChronometer implements IRest {
    className = 'Configurações de Cronômetro';

    soundIndex!: number;
    soundRepeat!: boolean;
    soundVolume!: number;

    constructor(data?: any) {
        if (data) this.Deserialize(data);
    }

    Serialize() {
        const out = {
            soundIndex: this.soundIndex,
            soundRepeat: this.soundRepeat,
            soundVolume: Math.round(this.soundVolume * 100)
        };
        return out;
    }

    Deserialize(data: any) {
        if (data) {
            this.soundIndex = data['soundIndex'] || 0;
            this.soundRepeat = data['soundRepeat'] || false;
            this.soundVolume = (data['soundVolume'] || 0) / 100;
        }
        return this;
    }

}

export class UserPreferences implements IRest {
    className = 'Preferências do Usuário';

    studyMinutesPerDay!: Array<boolean>;
    studyReviewDays!: Array<number>;
    performanceMin!: number;
    performanceMax!: number;
    firstDayOfWeek!: number;
    chronometer!: UserPreferencesChronometer;
    // sliderMin!: number; //Uso Interno
    // sliderMax!: number; //Uso Interno

    constructor(data?: any) {
        if (data) this.Deserialize(data);
    }

    Serialize() {
        const out = {
            studyMinutesPerDay: this.studyMinutesPerDay.map((s: any) => s ? s = 120 : s = 0),
            studyReviewDays: this.studyReviewDays,
            performanceMin: this.performanceMin,
            performanceMax: this.performanceMax,
            firstDayOfWeek: this.firstDayOfWeek,
            chronometer: this.chronometer.Serialize()
            // performanceMin: this.sliderMin / 2,
            // performanceMax: ((this.sliderMax / 2) + 50)
        };
        return out;
    }

    Deserialize(data: any) {
        if (data) {
            this.studyMinutesPerDay = (data['studyMinutesPerDay'] || []).map((s: any) => s > 0 ? s = true : s = false);
            this.studyReviewDays = (data['studyReviewDays'] || []);
            this.performanceMin = data['performanceMin'] || 0;
            this.performanceMax = data['performanceMax'] || 0;
            this.firstDayOfWeek = data['firstDayOfWeek'] || 0;
            this.chronometer = new UserPreferencesChronometer(data['chronometer'] || {});
            // this.sliderMin = (data['performanceMin'] || 0) * 2;
            // this.sliderMax = ((data['performanceMax'] || 0) - 50) * 2;
        }
        return this;
    }

}

export class Login implements IRest {
    className = 'Login';

    user!: User;
    token!: string;

    constructor(data?: any) {
        if (data) this.Deserialize(data);
    }

    Serialize() {
        const out = {
            user: this.user.Serialize(),
            token: this.token
        };
        return out;
    }

    Deserialize(data: any) {
        if (data) {
            this.user = new User(data['user'] || {});
            this.token = data['token'] || '';
        }
        return this;
    }

}

export class AccessToken implements IRest {
    className = 'Token JWT';

    id!: string;
    user!: User;
    subscribed!: boolean;
    createdAt!: Date;
    expiresAt!: Date;

    constructor(data?: any) {
        if (data) this.Deserialize(data);
    }

    Serialize() {
        const out = {
            id: this.id,
            user: this.user.Serialize(),
            subscribed: this.subscribed,
            iat: this.createdAt,
            exp: this.expiresAt
        };
        return out;
    }

    Deserialize(data: any) {
        if (data) {
            this.id = data['id'] || '';
            this.user = new User(data['user'] || {});
            this.subscribed = data['subscribed'] || false;
            this.createdAt = data['iat'] ? new Date(data['iat'] * 1000) : new Date(2000, 0);
            this.expiresAt = data['exp'] ? new Date(data['exp'] * 1000) : new Date(2000, 0);
        }
        return this;
    }

}

export class PasswordRecovery implements IRest {
    className = 'Recuperação de Senha';

    email!: string;
    password!: string;
    code!: string;

    constructor(data?: any) {
        if (data) this.Deserialize(data);
    }

    Serialize() {
        const out = {
            email: this.email,
            password: this.password,
            code: this.code
        };
        return out;
    }

    Deserialize(data: any) {
        if (data) {
            this.email = data['email'] || '';
            this.password = data['password'] || '';
            this.code = data['code'] || '';
        }
        return this;
    }

}

export class ChangePassword implements IRest {
    className = 'Alteração de Senha';

    oldPassword!: string;
    newPassword!: string;

    constructor(data?: any) {
        if (data) this.Deserialize(data);
    }

    Serialize() {
        const out = {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword
        };
        return out;
    }

    Deserialize(data: any) {
        if (data) {
            this.oldPassword = data['oldPassword'] || '';
            this.newPassword = data['newPassword'] || '';
        }
        return this;
    }

}

export class CheckUsername implements IRest {
    className = 'Verificação de Username';

    username!: string;
    exists!: boolean;

    constructor(data?: any) {
        if (data) this.Deserialize(data);
    }

    Serialize() {
        const out = {
            username: this.username,
            exists: this.exists
        };
        return out;
    }

    Deserialize(data: any) {
        if (data) {
            this.username = data['username'] || '';
            this.exists = data['exists'] || false;
        }
        return this;
    }

}

export class UserImage implements IRest {
    className = 'Imagem do Usuário';

    userId!: number;
    image!: string;

    constructor(data?: any) {
        if (data) this.Deserialize(data);
    }

    Serialize() {
        const out = {
            image: this.image
        };
        return out;
    }

    Deserialize(data: any) {
        if (data) {
            this.userId = data['userId'] || 0;
            this.image = data['image'] || '';
        }
        return this;
    }
}


export class UserRegisterCode implements IRest {
    className = 'Código de Registro';

    name!: string;

    constructor(data?: any) {
        if (data) this.Deserialize(data);
    }

    Serialize() {
        const out = {
            name: this.name,
        };
        return out;
    }

    Deserialize(data: any) {
        if (data) {
            this.name = data['name'] || '';
        }
        return this;
    }
}
