import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgxMaskModule } from 'ngx-mask';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from './pipes/pipes.module';
import { ModalModule } from '../private/modal/modal.module';

import { AlertComponent } from './alert/alert.component';
import { ChronometerComponent } from './chronometer/chronometer.component';
import { SettingsComponent } from './chronometer/settings/settings.component';
import { ColorsComponent } from './colors/colors.component';
import { HeaderButtonsComponent } from './header-buttons/header-buttons.component';
import { LoadingComponent } from './loading/loading.component';
import { ToastComponent } from './toast/toast.component';
import { SubscriptionAlertComponent } from './subscription-alert/subscription-alert.component';


@NgModule({
  declarations: [
    AlertComponent,
    ChronometerComponent,
    SettingsComponent,
    ColorsComponent,
    LoadingComponent,
    ToastComponent,
    HeaderButtonsComponent,
    SubscriptionAlertComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    ModalModule,
    NgbDropdownModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    AlertComponent,
    ChronometerComponent,
    ColorsComponent,
    HeaderButtonsComponent,
    LoadingComponent,
    ToastComponent,
    SubscriptionAlertComponent
  ]
})
export class SharedModule { }
