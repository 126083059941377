import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';
import { DatePipe } from '@angular/common';

import { InterceptorService } from './services/interceptor.service';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';


const routes: Routes = [
  { path: '', loadChildren: () => import('./public/public.module').then(m => m.PublicModule) },
  { path: 'u', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
  { path: 'a', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  { path: 'p', loadChildren: () => import('./private/private.module').then(m => m.PrivateModule) },
  { path: '**', redirectTo: '' }
];


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { useHash: false }),
    JwtModule.forRoot({
      config: {
        authScheme: 'Bearer ',
        skipWhenExpired: true,
        tokenGetter: () => { return localStorage.getItem('token'); },
        allowedDomains: ['localhost:3000', 'localhost:4200', 'staging.estudei.com.br', 'api.estudei.com.br']
      }
    }),
    GuidedTourModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    GuidedTourService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
